import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { setIsItemHistory, setLoadedData, setLocationFilter, setPaymentMethod, setPaymentStatus, setReportColumn, setReportDateRange, setReportInfo, setReportSideBarNav, setReportSideBarNavActiveItem, setReportType } from "../../redux/report/report.actions";
import { selectReportSideBarNavActiveItem, selectReportType } from "../../redux/report/report.reselect";
import { setTabTitle } from '../../utils/tab-title.utils';

import { PURCHASE_REPORT_BY_PAYMENT_INFO, PURCHASE_REPORT_BY_PAYMENT_COLUMN_TEMPLATE } from './purchase-report-by-payment.template';
import { PURCHASE_REPORT_PAYMENT_DETAILS_INFO, PURCHASE_REPORT_PAYMENT_DETAILS_COLUMN_TEMPLATE } from './purchase-report-payment-details.template';
import { PURCHASE_REPORT_OPERATING_EXPENSE_INFO, PURCHASE_REPORT_OPERATING_EXPENSE_COLUMN_TEMPLATE } from './purchase-report-by-operating-expense.template';
import { PURCHASE_REPORT_BY_ITEM_INFO, PURCHASE_REPORT_BY_ITEM_COLUMN_TEMPLATE } from './purchase-report-by-item.template';
import { PURCHASE_REPORT_BY_SUPPLIER_INFO, PURCHASE_REPORT_BY_SUPPLIER_COLUMN_TEMPLATE } from './purchase-report-by-supplier.template';
import { PURCHASE_REPORT_BY_SUPPLIER_BALANCE_INFO, PURCHASE_REPORT_BY_SUPPLIER_BALANCE_COLUMN_TEMPLATE } from './purchase-report-by-supplier-balance.template';


import Report from "../../components/report/report.component";
import { setFilterEmpty } from "../../redux/filter/filter.actions";

const TYPE = {
    PURCHASE_REPORT: "PURCHASE_REPORT",
}

const SUB_ITEMS = [
    {
        text: "Purchase by Payment",
        info: PURCHASE_REPORT_BY_PAYMENT_INFO,
        reportColumn: PURCHASE_REPORT_BY_PAYMENT_COLUMN_TEMPLATE
    },
    {
        text: "Payment Details",
        info: PURCHASE_REPORT_PAYMENT_DETAILS_INFO,
        reportColumn: PURCHASE_REPORT_PAYMENT_DETAILS_COLUMN_TEMPLATE
    },
    {
        text: "Operating Expense",
        info: PURCHASE_REPORT_OPERATING_EXPENSE_INFO,
        reportColumn: PURCHASE_REPORT_OPERATING_EXPENSE_COLUMN_TEMPLATE
    },
    {
        text: "Purchasing by Product",
        info: PURCHASE_REPORT_BY_ITEM_INFO,
        reportColumn: PURCHASE_REPORT_BY_ITEM_COLUMN_TEMPLATE
    },
    {
        text: "Purchasing by Supplier",
        info: PURCHASE_REPORT_BY_SUPPLIER_INFO,
        reportColumn: PURCHASE_REPORT_BY_SUPPLIER_COLUMN_TEMPLATE
    },
    {
        text: "Supplier Balance",
        info: PURCHASE_REPORT_BY_SUPPLIER_BALANCE_INFO,
        reportColumn: PURCHASE_REPORT_BY_SUPPLIER_BALANCE_COLUMN_TEMPLATE
    }
]

const dataKeys = []

const PurchaseReport = ({
    reportType,
    setReportType,
    setReportColumn,
    setReportSideBarNav,
    setReportSideBarNavActiveItem,
    reportSideBarNavActiveItem,
    setReportInfo,
    setReportDateRange,
    setLoadedData,
    setPaymentMethod,
    setPaymentStatus,
    setLocationFilter,
    setIsItemHistory,
    setFilterEmpty,
}) => {
    const [confrimLoadedData, setConfirmLoadedData] = useState(false);
    const [isFristRender, setIsFristRender] = useState(true);
    const endpoint = reportType === TYPE.PURCHASE_REPORT ? `${SUB_ITEMS[reportSideBarNavActiveItem].info.path}${SUB_ITEMS[reportSideBarNavActiveItem].info.subPath}` :
        `${SUB_ITEMS[0].info.path}${SUB_ITEMS[0].info.subPath}`

    useEffect(() => {
        if (reportType === TYPE.PURCHASE_REPORT) {
            setReportInfo(SUB_ITEMS[reportSideBarNavActiveItem].info);
            setReportSideBarNav(SUB_ITEMS);
        } else {
            setReportInfo(SUB_ITEMS[0].info);
            setReportSideBarNav(SUB_ITEMS);
        }
    }, [reportSideBarNavActiveItem]);

    useEffect(() => {
        // setReportDateRange({})
        setIsFristRender(false);
    }, [])

    useEffect(() => {
        setTabTitle(`Purchasing Report`)
    }, [])

    useEffect(() => {
        setIsItemHistory(false)
    }, [])

    useEffect(() => {
        if (reportType !== TYPE.PURCHASE_REPORT) {
            setLoadedData({
                data: null,
                skip: null,
                totalNumItems: 0
            })

            setLocationFilter(null);
            setPaymentMethod(null);
            setPaymentStatus(null);
            setReportDateRange({});
            setReportSideBarNavActiveItem(0);
            setFilterEmpty();
        }

        setReportType(TYPE.PURCHASE_REPORT);
        setConfirmLoadedData(true);
    }, [])

    useEffect(() => {
        return () => {
            setReportInfo(null);
        }
    }, [])

    return (
        <>
            {!isFristRender && confrimLoadedData &&
                <Report
                    reportColumn={SUB_ITEMS[reportSideBarNavActiveItem].reportColumn}
                    endpoint={endpoint}
                    dataKeys={dataKeys}
                />
            }
        </>
    )
}

const mapStateToProps = createStructuredSelector({
    reportSideBarNavActiveItem: selectReportSideBarNavActiveItem,
    reportType: selectReportType
})

const mapDispatchToProps = (disptach) => ({
    setReportInfo: (reportInfo) =>
        disptach(setReportInfo(reportInfo)),
    setReportColumn: (data) =>
        disptach(setReportColumn(data)),
    setReportSideBarNav: (data) =>
        disptach(setReportSideBarNav(data)),
    setReportSideBarNavActiveItem: (data) =>
        disptach(setReportSideBarNavActiveItem(data)),
    setReportDateRange: (data) =>
        disptach(setReportDateRange(data)),
    setReportType: (data) =>
        disptach(setReportType(data)),
    setLoadedData: (data) =>
        disptach(setLoadedData(data)),
    setPaymentMethod: (data) =>
        disptach(setPaymentMethod(data)),
    setPaymentStatus: (data) =>
        disptach(setPaymentStatus(data)),
    setLocationFilter: (data) =>
        disptach(setLocationFilter(data)),
    setFilterEmpty: (data) =>
        disptach(setFilterEmpty(data)),
    setIsItemHistory: (data) =>
        disptach(setIsItemHistory(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PurchaseReport));