import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';

export const PURCHASE_REPORT_BY_SUPPLIER_BALANCE_INFO = {
  reportTitle: "Purchase Reports",
  path: "/purchasereports",
  subPath: "/purchasebysupplierbalance",
  listPage: "/purchase-reports",
  pagePath:"suppliers",
  hasDateRange: false,
  hasChart: false,
  tableHasGrandTotal: true,
  hasCreditFilter: true,
  hasLocationFilter: true,
  hasSearch: true
}

export const PURCHASE_REPORT_BY_SUPPLIER_BALANCE_COLUMN_TEMPLATE = [
  {
    Header: "Supplier Name",
    accessor: "supplierName",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
    Footer: 'Grand Total'
  },
  // {
  //   Header: "Beginning Credit (ETB)",
  //   accessor: "beginningCredit",
  //   Cell: ({ value }) => {
  //     const isMobileView = useMediaQuery({ maxWidth: 991 });

  //     if (isMobileView) {
  //       return `Beginning Credit: ${value ? displayCellValueNumberToTwoDecimal(value) : 0} ETB`;
  //     }

  //     return displayCellValueNumberToTwoDecimal(value);
  //   },
  // },
  {
    Header: "Total Credit (ETB)",
    accessor: "totalCredit",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Beginning Credit: ${value ? displayCellValueNumberToTwoDecimal(value) : 0} ETB`;
      }

      return displayCellValueNumberToTwoDecimal(value);
    },
    Footer: 'grandTotalCredit'
  },
]
