import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';

export const SALES_REPORT_BY_CUSTOMER_BALANCE_INFO = {
  reportTitle: "Sales Reports",
  path: "/salesreports",
  subPath: "/salesbycustomerbalance",
  listPage: "/sales-reports",
  pagePath:"customers",
  hasDateRange: false,
  hasChart: false,
  tableHasGrandTotal: true,
  hasCreditFilter: true,
  hasLocationFilter: true,
  hasSearch: true
}

export const SALES_REPORT_BY_CUSTOMER_BALANCE_COLUMN_TEMPLATE = [
  {
    Header: "Customer Name",
    accessor: "customerName",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
    Footer: 'Grand Total'
  },
  {
    Header: "Beginning Credit (ETB)",
    accessor: "beginningCredit",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Beginning Credit: ${value ? displayCellValueNumberToTwoDecimal(value) : 0} ETB`;
      }

      return displayCellValueNumberToTwoDecimal(value);
    },
  },
  {
    Header: "Total Credit (ETB)",
    accessor: "totalCredit",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Beginning Credit: ${value ? displayCellValueNumberToTwoDecimal(value) : 0} ETB`;
      }

      return displayCellValueNumberToTwoDecimal(value);
    },
    Footer: 'grandTotalCredit'
  },
]
