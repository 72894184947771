import React, { useRef } from "react";

import { useEffect } from "react";
import { useState } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import DocumentHeader from "../../../components/document-header/document-header.componenet";
import DocumentBody from "../../../components/document-body/document-body.componenet";
import { SALES_ORDERS_COLUMNS_DOCUMENT } from "./sales-orders-columns-document.data";
import useFetchDocument from "../../../effects/use-fetch-document";
import { withRouter } from "react-router-dom";
import { authTokenSelector, selectSettings } from "../../../redux/user/user.reselect";
import { selectIsRefresh } from "../../../redux/refresh/refresh.reselect";
import { getUserSettings } from "../../../utils/auth_token.utils";

const SalesOrderDocument = ({
    authTokenFromState,
    match,
    isRefresh,
    settings
}) => {
    const contentRef = useRef(null);
    const title = "Sales Order"
    const path = "/salesorders";

    const endpoint = `${path}/doucments/${match.params.uuid}`;
    const { data, error, isLoading } = useFetchDocument(
        endpoint,
        authTokenFromState,
        isRefresh
    );

    const [dataIsReady, setDataIsReady] = useState(false);
    const [dataForDoucment, setDataForDoucment] = useState(null);
    useEffect(() => {
        setDataForDoucment({
            header: [
                data.company,
                data.salesOrderSummary
            ],
            body: {
                sections: [
                    data.bankAccountSummary,
                    data.customerWithBillingAddressSummary,
                    data.customerWithShippingAddressSummary,
                    data.deliveryDate,
                    data.shippingMethodSummary
                ],
                table: data.items,
                total: data.total
            }
        })
        setDataIsReady(true)
    }, [data]);

    const [salesOrderColumnsDoc, setSalesOrderColumnsDoc] = useState(SALES_ORDERS_COLUMNS_DOCUMENT);
    const [isSalesOrderColumnDocSet, setIsSalesOrderColumnDocSet] = useState(false);

    useEffect(() => {
        const setting = getUserSettings(settings);
        if (setting && !setting.settings.length && isSalesOrderColumnDocSet === false) {    
        
            if (Object.keys(SALES_ORDERS_COLUMNS_DOCUMENT).length) {
                SALES_ORDERS_COLUMNS_DOCUMENT.splice(4, 1);
            }
    
            setSalesOrderColumnsDoc(SALES_ORDERS_COLUMNS_DOCUMENT);
            setIsSalesOrderColumnDocSet(true);
        } else {
            if (Object.keys(SALES_ORDERS_COLUMNS_DOCUMENT).length) {
                SALES_ORDERS_COLUMNS_DOCUMENT.splice(3, 1);
            }
    
            setSalesOrderColumnsDoc(SALES_ORDERS_COLUMNS_DOCUMENT);
            setIsSalesOrderColumnDocSet(true);
        }
    }, [salesOrderColumnsDoc]);

    return (
        <>{dataIsReady &&
            <>
                <DocumentHeader
                    title={title}
                    contentRef={contentRef}
                />
                <DocumentBody
                    title={title}
                    endpoint={endpoint}
                    contentRef={contentRef}
                    data={dataForDoucment}
                    tableColumns={SALES_ORDERS_COLUMNS_DOCUMENT}
                    tableData={data.items}
                    error={error}
                    isLoading={isLoading}
                />
            </>
        }

        </>
    )
}

const mapStateToProps = createStructuredSelector({
    authTokenFromState: authTokenSelector,
    isRefresh: selectIsRefresh,
    settings: selectSettings
});

export default withRouter(connect(mapStateToProps, null)(SalesOrderDocument));
