export const getAuthToken = (authTokenFromState) => {
    const loggedInUser =JSON.parse(localStorage.getItem("user"));
    let storedAuthToken = null;

    if(loggedInUser){
         storedAuthToken = loggedInUser.authToken;
    }

    const token = authTokenFromState ? authTokenFromState : storedAuthToken;
    
    return {
        Authorization: "Bearer " + token
    }
} 

export const getUserSettings = (settingsFromState) => {
    const loggedInUser =JSON.parse(localStorage.getItem("user"));
    let storedSettings = null;

    if(loggedInUser){
         storedSettings = loggedInUser.settings;
    }

    const settings = settingsFromState ? settingsFromState : storedSettings;

    console.log("show settings value for setting: ", settings)
    
    return {
        settings
    }
} 